/* General */

* {
  box-sizing: border-box;
}

html {
  font-size: 10px;
  text-rendering: geometricPrecision;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  background-color: var(--white);

  color: var(--text);
  font-family: var(--font-primary);

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-y: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-secondary);
  font-weight: 600;
  color: rgba(0, 0, 0, 0.86);
}

h1,
h2,
h3 {
  margin-top: 3.2rem;
  margin-bottom: 1.6rem;
}

h4,
h5,
h6 {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
}

h1 {
  font-size: 3.2rem;
}

h2 {
  font-size: 2.8rem;
}

h3 {
  font-size: 2.4rem;
}

h4 {
  font-size: 2rem;
}

h5 {
  font-size: 1.6rem;
}

h6 {
  font-size: 1.4rem;
}

p {
  margin: 0 0 1.6rem;
}

strong {
  font-weight: 500;
}

small {
  font-size: 1.2rem;
}

blockquote {
  padding: 1.6rem 3.2rem;
  margin: 0 0 3.2rem;

  border-left: 8px solid #eee;

  font-size: 1.6rem;
  font-style: italic;
}

body,
button,
input,
select,
textarea {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

a {
  color: var(--text);
  text-decoration: none;
}

figure {
  margin: 0;
}
img {
  vertical-align: middle;
}

code,
pre {
  font-family: "Fira Code", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
  width: 100%;
}

code {
  color: var(--emerald);
}

#root {
  height: 100%;
  width: 100%;
}

.black-text {
  color: black;
}

.primary-text {
  color: #202124;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.bolder-text {
  font-weight: 700;
}

.bullet-number {
  background: var(--brand);
  width: 26px;
  height: 26px;
  border-radius: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  flex-shrink: 0;
  color: white;
}
.keyItem {
  position: relative;
  display: flex;
  align-items: center;
  margin-block: 16px;
  max-width: 30%;
}
.iframeWrapper {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin-inline: 2px;
  margin-bottom: 10px;
  gap: 3rem;
}
.iframeWrapper iframe {
  min-height: 300px;
  min-width: 500px;
  width: 100%;
  height: 100%;
  border: 0;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
}

.apiDialog {
  position: absolute;
  inset: 50% auto auto 50%;
  border: 0px;
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 7px;
  outline: none;
  padding: 32px 32px 0px;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  width: 30%;
  min-height: 200px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 6px 0px;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  margin: auto;
}

/* Tooltip text */
.tooltip .tooltiptext {
  width: 120px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 6px 0px;
  color: var(--black);
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  top: -30px;
  left: -6%;
}

@media only screen and (max-width: 1024px) {
  .iframeWrapper {
    grid-template-columns: repeat(1, 1fr);
    margin: auto;
  }
  .iframeWrapper iframe {
    width: 80%;
  }
  .keyItem {
    max-width: 70%;
  }
}

@media only screen and (max-width: 640px) {
  .mobile-scroll-lock {
    overflow: hidden;
  }
  .keyItem {
    max-width: 100%;
  }
  .apiDialog {
    width: 80%;
  }
}

/* ScrollbarStyles.css */

/* Customizes the whole scrollbar */
::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
  height: 10px;
}

.main-container {
  margin-top: 5rem;
  display: flex;
  height: 100vh; /* Full viewport height */
  padding-left: 1rem;
  margin-left: 300px; /* Width of the sidebar when open */
  transition: margin-left 0.5s ease; /* Smooth transition */
}

.main-container.sidebarClosed {
  margin-left: 0;
}

.main-container_banner-shown {
  margin-top: 5rem;
  display: flex;
  height: 100vh; /* Full viewport height */
  padding-left: 1rem;
}

.main-sidebar {
  /* Fixed width or flexible, as you prefer */
  background-color: #f0f0f0; /* Example styling */
}
.padding-sides {
  padding-left: 6rem;
  padding-right: 6rem;
  overflow: hidden;
}
.main-content {
  flex-grow: 1; /* Takes up the remaining space */
  overflow-y: auto; /* Adds scroll to content if needed */
  background-color: #fff; /* Example styling */
}
.main-container_banner-shown .main-content {
  padding-bottom: 3rem;
}
.centered-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh; /* Full height of the viewport */
  text-align: center;
}
.span-link {
  cursor: pointer;
  color: var(--brand);
  font-size: 14px;
}
.centered-container img {
  max-width: 100%;
  height: auto;
}

.search-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.search-input {
  border: none;
  outline: none;
  padding: 8px 10px;
  font-size: 16px;
  width: 100%; /* Adjust width as needed */
  background-color: #f5f5f5;
}

/* Style the placeholder text */
.search-input::placeholder {
  color: #888;
}

/* Add some styles on focus */
.search-input:focus {
  border-bottom-color: #5177fc !important; /* Color change on focus */
  border-bottom-width: 0.5px !important;
}
.search-input:hover {
  border-bottom-color: #5177fc; /* Color change on focus */
  border-bottom-width: 0.5px;
}

/* Customizes the background of the scrollbar track */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* color of the tracking area */
}

/* Customizes the scrollbar handle */
::-webkit-scrollbar-thumb {
  background: #888; /* color of the scroll thumb */
  border-radius: 8px; /* roundness of the scroll thumb */
}

/* Changes the scrollbar handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* color of the scroll thumb on hover */
}

.MuiSwitch-root .Mui-checked .MuiSwitch-thumb {
  background: #5177fc !important;
}
.MuiSwitch-root .Mui-checked .MuiSwitch-track {
  background: #9bb0fd !important;
}
.MuiDialog-container h2 {
  text-transform: none !important;
}
